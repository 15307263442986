import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, Navigation, Scrollbar } from 'swiper';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperBasic from '../Swiper/SwiperBasic';

SwiperCore.use([Navigation, Scrollbar, A11y]);

interface ItemProps {
  itemData: DataItem[];
  type?: string;
}

export interface DataItem {
  id: string | number;
  img: ImgHTMLAttributes<any>['src'];
  headerTit: string;
  headerTitEn: string;
  headerDec: string;
  category: string;
  title?: string;
  date?: string;
  dec: [string];
  blit?: boolean;
  tip: string;
  link: string;
}

const SwiperCustom = styled(SwiperBasic)`
  padding: 0;

  .swiper-wrapper {
    display: inline-flex;
    align-items: stretch;

    .swiper-slide {
      overflow: hidden;
      width: 544px;
      margin-right: 32px;
      padding-left: 64px;
      background: #fff;
      box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.05);
      border-radius: 0 0 100px 0;
      height: auto;

      &:last-child {
        margin-right: 0 !important;
      }

      ${breakpoint(1160)} {
        width: 41.03vw;
        margin-right: 3.45vw;
        padding-left: 5.52vw;
      }
      ${breakpoint(`tablet`)} {
        width: 340px;
        margin-right: 16px;
        padding-left: 39px;
      }
      ${breakpoint(`mobile`)} {
        width: 100%;
        margin-right: 0;
      }
    }
  }
`;

const CardLayout = styled.div``;
const CardHeader = styled.div`
  padding: 32px 40px 40px 0;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 62px;
    height: 62px;
    border: 31px solid #1cabe2;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
  }

  .dec {
    margin-top: 16px;
    line-height: 2;
    min-height: 64px;
  }
`;
const CardImage = styled.div`
  overflow: hidden;
  position: relative;

  .img-box {
    width: 100%;
    padding-top: 44.12%;
  }
`;
const CardTitle = styled.div`
  background: #fff;
  padding: 30px 64px 49px 0;
  border-radius: 0 0 40px 0;
  margin: 0;

  .cate {
    color: #1cabe2;
    margin-bottom: 8px;
    font-weight: 700;
  }

  ${Tit} {
    line-height: 38px;
    margin-bottom: 16px;
    display: block;
  }

  .date {
    line-height: 32px;
  }

  .dec-wrap {
    border-top: 1px solid #2d2926;
    padding-top: 16px;
    line-height: 2;

    &.blit {
      li {
        position: relative;
        padding-left: 10px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #2d2926;
          position: absolute;
          left: 0;
          top: 13px;
        }
      }
    }
  }

  .tip {
    color: #e2231a;
    line-height: 2;
    margin-top: 8px;
  }

  ${breakpoint(1160)} {
    padding-right: 5.52vw;
  }
  ${breakpoint(`tablet`)} {
    padding-right: 24px;
  }
`;

const PartnerCard: FC<ItemProps> = ({ itemData, type }) => (
  <SwiperCustom>
    <Swiper
      slidesPerView="auto"
      navigation
      scrollbar={{ draggable: true }}
      spaceBetween={20}
      breakpoints={{
        641: {
          spaceBetween: 20,
        },
      }}
    >
      {itemData.map((row) => (
        <SwiperSlide key={row.id}>
          <CardLayout>
            <CardHeader>
              <h3>
                <Tit size="s2" color="sky">
                  {row.headerTit}
                </Tit>
              </h3>
              <Tit size="s2" className="tit-en">
                {row.headerTitEn}
              </Tit>
              <p className="dec">{row.headerDec}</p>
            </CardHeader>
            <CardImage>
              <div
                className="img-box"
                css={`
                  background-image: url(${row.img});
                `}
              />
            </CardImage>
            <CardTitle>
              <p className="cate">{row.category}</p>
              <Tit size="s3">{row.title}</Tit>
              <Tit size="s4" className="date">
                {row.date}
              </Tit>
              <ul className={row.blit ? `dec-wrap blit` : `dec-wrap`}>
                {row.dec.map((row2) => (
                  <li>
                    <p className="dec">{row2}</p>
                  </li>
                ))}
              </ul>
              {row.tip ? <p className="tip">{row.tip}</p> : ``}
            </CardTitle>
          </CardLayout>
        </SwiperSlide>
      ))}
    </Swiper>
  </SwiperCustom>
);
export default PartnerCard;
